/* eslint-env browser */
import { session, auth } from '@awise/session/store';
import { jitsuAnalytics } from '@jitsu/js';

const ROLES = {
    Manager: 'Gerente',
    FinancialManager: 'Gerente Financeiro',
    Cashier: 'Caixa',
    Salesperson: 'Vendedor',
    RestrictedSalesperson: 'Vendedor Restrito',
    Storekeeper: 'Estoquista',
    Purchaser: 'Comprador',
    Custom: 'Personalizado',
    RestrictedCashierSalesperson: 'Caixa Restrito',
    StoreOperator: 'Operador de Loja',
    BasicStorekeeper: 'Estoquista Básico',
};

if (process.env.JITSU_URL && process.env.JITSU_WRITE_KEY) {
    window.jitsu = jitsuAnalytics({
        host: process.env.JITSU_URL,
        writeKey: process.env.JITSU_WRITE_KEY,
    });
}

window.dataLayer = window.dataLayer || [];
function gtag() {
    // eslint-disable-next-line no-undef
    dataLayer.push(arguments);
}

session.onChange((sessionData) => {
    if (sessionData === null) {
        return;
    }
    const authData = auth.value();
    window.userGuiding.identify(authData.userLogin, {
        nome: authData.userName,
        lifetime: getLifetime(sessionData) + 'M',
        cpf_cnpj: sessionData.branch.nationalIdNum,
        nome_fantasia: sessionData.branch.fantasyName,
        status: sessionData.subscription?.hasPaidBill ? 'CLIENTE' : 'FREE',
        sistema: 'AWISE',
        tier: sessionData.subscription?.planTier,
        nome_plano: sessionData.subscription?.hasPaidBill ? sessionData.subscription.planName : 'FREE',
        roles: identifyRoles(sessionData),
        subscription_activation_date: sessionData.subscription?.branchConvertedDate,
        company: {
            id: sessionData.company.id,
            nome: sessionData.company.name,
            nome_fantasia: sessionData.branch.fantasyName,
        },
    });

    if (window.jitsu) {
        identifyUserToJitsu(sessionData);
    }

    if (process.env.ANALYTICS_TAG_ID) {
        gtag('config', process.env.ANALYTICS_TAG_ID, {
            user_id: authData.userId,
        });

        gtag('set', 'user_properties', {
            subscription_plan: sessionData.subscription?.hasPaidBill ? sessionData.subscription.planName : 'FREE',
        });
    }
});

function identifyRoles(sessionData) {
    if (sessionData.isAdministrator) {
        return 'Administrador';
    }
    return sessionData.roles.map((role) => ROLES[role] ?? role).join(', ');
}

function identifyUserToJitsu(sessionData) {
    const branchTraits = getJitsuTraitsFromSessionData(sessionData);
    const storedTraits = JSON.parse(localStorage.getItem('jitsuStoredTraits')) || [];
    const branchIndex = storedTraits.findIndex((branch) => branch.branch === branchTraits.branch);

    if (branchIndex === -1) {
        storedTraits.push(branchTraits);
    } else {
        storedTraits[branchIndex] = branchTraits;
    }

    localStorage.setItem('jitsuStoredTraits', JSON.stringify(storedTraits));

    const authData = auth.value();
    const traits = {
        nome: authData.userName,
        user_id: authData.userId,
        ...joinTraits(storedTraits),
    };
    const serializedTraits = JSON.stringify(traits);

    if (localStorage.getItem('jitsuLastIdentify') !== serializedTraits) {
        localStorage.setItem('jitsuLastIdentify', serializedTraits);
        window.jitsu.identify(authData.userLogin, traits);
    }
}

/**
 * @template {Object} T
 * @param {T[]} traits
 * @returns {T}
 */
function joinTraits(traits) {
    return traits.reduce((acc, value) => {
        for (const [key, val] of Object.entries(value)) {
            acc[key] = acc[key] ? `${acc[key]}, ${val}` : val;
        }

        return acc;
    }, {});
}

/** @param {import('@awise/session/models').Session} sessionData */
function getJitsuTraitsFromSessionData(sessionData) {
    return {
        company: sessionData.company.id,
        branch: sessionData.branch.id,
        lifetime: getLifetime(sessionData),
        cpf_cnpj: sessionData.branch.nationalIdNum ? sessionData.branch.nationalIdNum : 'null',
        fantasy_name: sessionData.branch.fantasyName,
        status: sessionData.subscription?.branchConvertedDate ? 'CLIENTE' : 'FREE',
        plan_name: sessionData.subscription?.planName || '',
    };
}

/** @param {import('@awise/session/models').Session} sessionData */
function getLifetime(sessionData) {
    const converted = new Date(sessionData.branch.createdDateTime);
    const diffInMs = new Date().getTime() - converted.getTime();
    const diffInMonths = Math.ceil(diffInMs / 1000 / 60 / 60 / 24 / 30);
    return `${diffInMonths}`;
}
